import { Icon } from "nystem-components";

const SelectIcon = ({ model, value }) => {
  const className = model.className ? model.className.join(" ") : "";
  return (
    <Icon
      className={className}
      icon={value}
      deg={model.deg !== "0" && model.deg}
    />
  );
};

export default SelectIcon;
