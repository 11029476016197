import "./video.css";
import { Wrapper } from "nystem-components";

const videoProviders = [
  {
    name: "youtube",
    // eslint-disable-next-line max-len
    test: /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
    embedUrl:
      "//www.youtube.com/embed/{{id}}?rel=0&amp;showinfo=0&html5=1&enablejsapi=1",
  },
  {
    name: "youku",
    test: /^(?:https?:\/\/)?v\.youku\.com\/v_show\/id_([^"&/]+)\.html/i,
    embedUrl: "//player.youku.com/embed/{{id}}",
  },
  {
    name: "youku",
    test: /^(?:https?:\/\/)?player\.youku\.com\/player\.php\/sid\/([^"&/]+)/i,
    embedUrl: "//player.youku.com/embed/{{id}}",
  },
  {
    name: "vimeo",
    // eslint-disable-next-line max-len
    test: /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/,
    embedUrl: "//player.vimeo.com/video/{{id}}",
  },
];

const getEmbedURL = (value) => {
  const validVideoProvider = videoProviders.find((videoProvider) =>
    videoProvider.test.exec(value)
  );

  if (!validVideoProvider) return value;

  const idMatch = validVideoProvider.test.exec(value);
  if (!idMatch) return value;

  const url = validVideoProvider.embedUrl.replace("{{id}}", idMatch[1]);

  return url;
};

const AddCover = ({ children, addCover }) =>
  !addCover ? (
    children
  ) : (
    <Wrapper className="relative h-full w-full">
      <Wrapper className="absolute inset-0" />
      {children}
    </Wrapper>
  );

const TextVideo = ({ model, value }) =>
  value ? (
    <AddCover addCover={model.disabled}>
      <Wrapper
        src={getEmbedURL(value)}
        renderAs="iframe"
        className={model.className}
        allowFullScreen={true}
        frameBorder="0"
      />
    </AddCover>
  ) : null;

export default TextVideo;
