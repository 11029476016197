/* eslint-disable operator-assignment */
const DynamicLineGetSplines = (points) => {
  const nPaths = points.length;
  const x = points.map((item) => item.cx);
  const y = points.map((item) => item.cy);

  const weights = new Array(nPaths);
  for (let i = 0; i < nPaths; i++) {
    weights[i] = Math.sqrt(
      // eslint-disable-next-line no-restricted-properties
      Math.pow(x[i + 1] - x[i], 2) + Math.pow(y[i + 1] - y[i], 2)
    );
    weights[i] = weights[i] < 1 ? weights[i] : 1;
  }
  weights[nPaths] = weights[nPaths - 1];

  const px = computeControlPointsBigWThomas(x, weights);
  const py = computeControlPointsBigWThomas(y, weights);
  const paths = [];
  for (let i = 0; i < nPaths - 1; i++)
    paths.push(
      pathDescription(
        x[i],
        y[i],
        px.p1[i],
        py.p1[i],
        px.p2[i],
        py.p2[i],
        x[i + 1],
        y[i + 1]
      )
    );
  return paths;
};

function pathDescription(x1, y1, px1, py1, px2, py2, x2, y2) {
  return `M ${x1} ${y1} C ${px1} ${py1} ${px2} ${py2} ${x2} ${y2}`;
}

function computeControlPointsBigWThomas(K, W) {
  let p;
  p = [];

  const p1 = [];
  const p2 = [];
  const n = K.length - 1;

  const a = [];
  const b = [];
  const c = [];
  const d = [];
  const r = [];

  a[0] = 0;
  b[0] = 2;
  c[0] = -1;
  d[0] = 0;
  r[0] = K[0] + 0;

  for (let i = 1; i < n; i++) {
    a[2 * i - 1] = 1 * W[i] * W[i];
    b[2 * i - 1] = -2 * W[i] * W[i];
    c[2 * i - 1] = 2 * W[i - 1] * W[i - 1];
    d[2 * i - 1] = -1 * W[i - 1] * W[i - 1];
    r[2 * i - 1] = K[i] * (-W[i] * W[i] + W[i - 1] * W[i - 1]);

    a[2 * i] = W[i];
    b[2 * i] = W[i - 1];
    c[2 * i] = 0;
    d[2 * i] = 0;
    r[2 * i] = (W[i - 1] + W[i]) * K[i];
  }

  a[2 * n - 1] = -1;
  b[2 * n - 1] = 2;
  r[2 * n - 1] = K[n];

  c[2 * n - 1] = 0;
  d[2 * n - 2] = 0;
  d[2 * n - 1] = 0;

  p = Thomas4(r, a, b, c, d);

  for (let i = 0; i < n; i++) {
    p1[i] = p[2 * i];
    p2[i] = p[2 * i + 1];
  }

  return { p1: p1, p2: p2 };
}

function Thomas4(r, a, b, c, d) {
  let i;
  let m;
  const n = r.length;
  const p = new Array(n);

  a[0] = 0;
  c[n - 1] = 0;
  d[n - 2] = 0;
  d[n - 1] = 0;

  for (let i = 1; i < n; i++) {
    m = a[i] / b[i - 1];
    b[i] = b[i] - m * c[i - 1];
    c[i] = c[i] - m * d[i - 1];
    r[i] = r[i] - m * r[i - 1];
  }

  p[n - 1] = r[n - 1] / b[n - 1];
  p[n - 2] = (r[n - 2] - c[n - 2] * p[n - 1]) / b[n - 2];
  for (i = n - 3; i >= 0; --i) {
    p[i] = (r[i] - c[i] * p[i + 1] - d[i] * p[i + 2]) / b[i];
  }

  return p;
}

export default DynamicLineGetSplines;
