import React, { useEffect, useState } from "react";
import {
  InputWrapper,
  Wrapper,
  DateInputTime,
  SelectInput,
  UseValidator,
} from "nystem-components";
import moment from "my-moment";
import app from "nystem";
import validate from "./validate";

const DateRelDate = ({ model, view, setValue, value }) => {
  const { relSource, dateType = ["date", "time"] } = model;
  const [selectDays, setSelectDays] = useState(false);
  const [error, setValidated] = UseValidator({ view, validate, value, model });
  const validateAndSet = (value) => {
    setValue(value);
    setValidated(true);
  };

  useEffect(() => {
    const field = view.model.item.find((item) => item.id === relSource);
    const val = view.value[field.id];

    app()
      .database[field.source].get({ id: val })
      .then((data) => {
        const { from, to } = data.data || {};
        const options = [];
        let acc = from;
        while (to >= acc && options.length < 50) {
          options.push(acc);
          acc += 1000 * 60 * 60 * 24;
        }

        setSelectDays(
          options.map((_id) => ({
            _id,
            text: moment(_id).format("ddd DD/M", app().settings.timeZone),
          }))
        );
      });
  }, [relSource, view.model.item, view.value]);

  return (
    <InputWrapper model={model} error={error}>
      <Wrapper className="flex">
        {dateType.includes("time") && (
          <DateInputTime
            model={{ ...model, inputClassName: ["mr-2"] }}
            value={value}
            setValue={validateAndSet}
          />
        )}
        {dateType.includes("date") && (
          <SelectInput
            model={{
              id: "hepp",
              option: selectDays,
              render: "button",
              className: " ",
              noWrapper: true,
              limit: 1,
            }}
            setValue={(newValue) => {
              const newDate = moment(newValue).format(
                "YYYY-MM-DD",
                app().settings.timeZone
              );
              const time = moment(value || Date.now()).format(
                "HH:mm",
                app().settings.timeZone
              );
              validateAndSet(moment(`${newDate} ${time}`).valueOf());
            }}
            view={view}
            value={moment(value).startOf(model.utc).valueOf()}
          />
        )}
      </Wrapper>
    </InputWrapper>
  );
};
export default DateRelDate;
