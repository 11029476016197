import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const TokenView = ({ model, value }) =>
  value ? (
    <Wrapper
      className={model.className}
      renderAs="a"
      target="_blank"
      rel="noopener"
      href={value}
    >
      <ContentTypeRender items={model.item} />
    </Wrapper>
  ) : null;

export default TokenView;
