import React from "react";
import { ContentTypeView, InputWrapper, Wrapper } from "nystem-components";

const ReferenceBaseViewId = ({ model, view, value = [] }) => {
  value = value instanceof Array ? value : [value];
  const { renderFormat, className, source, itemClassName } = model;

  const option = (item, index) => (
    <ContentTypeView
      key={item}
      contentType={source}
      baseView={view}
      format={renderFormat || "view"}
      id={item}
      params={view.params}
      className={itemClassName}
      onReference={(item) => view.event(item.event, { ...item, model })}
    />
  );

  const baseViewId = view.baseView.id;
  const content = value.filter((id) => id === baseViewId).map(option);

  if (model.wrapper)
    return <InputWrapper model={model}>{content}</InputWrapper>;

  return <Wrapper className={className}>{content}</Wrapper>;
};
export default ReferenceBaseViewId;
