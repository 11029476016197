import {
  Link,
  SessionRole,
  Icon,
  NotficationView,
  Inserter,
  ContentTypeView,
} from "nystem-components";
import React, { useState, useEffect } from "react";

const NavbarLink = ({ children, ...props }) => {
  const classNames = "block p-2 rounded hover:bg-white-30-percent";

  return (
    <Link {...props} className={`${classNames} ${props.className || ""}`}>
      {children}
    </Link>
  );
};

const DropDownLink = ({ children, ...props }) => {
  const classNames =
    "block px-4 py-2 text-white rounded hover:bg-white-30-percent border-t-2 border-color-white dropdownlink";

  return (
    <Link {...props} className={`${classNames} ${props.className || ""}`}>
      {children}
    </Link>
  );
};

const PartMenu = () => {
  const [open, setOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  useEffect(() => {
    let first = true;
    const close = () => {
      if (first) {
        first = false;
        return;
      }
      if (open === true) setOpen(false);
      if (userOpen === true) setUserOpen(false);
    };

    window.addEventListener("click", close);
    return () => {
      window.removeEventListener("click", close);
    };
  }, [open, userOpen]);

  return (
    <header className="bg-primary sm:flex sm:justify-between sm:items-center py-1 shadow sticky top-0 z-30">
      <div className="flex items-center py-1 flex-grow">
        <SessionRole userrole="logged-in">
          <div className="relative">
            <div
              className="p-2 text-white rounded hover:bg-white-30-percent mx-1 ml-2 cursor-pointer"
              onClick={() => setOpen(!open)}
              onKeyPress={() => setOpen(!open)}
              role="button"
              tabIndex={0}
            >
              <Icon icon={open ? "xmark" : "bars"} className="w-12 h-12" />
            </div>
            {open && (
              <div className="absolute bg-primary rounded shadow w-330px mt-3 text-white text-xl menu-max-height">
                <ContentTypeView contentType="conference" format="menu" />
                <SessionRole userrole="config super">
                  <DropDownLink to="/admin">Admin</DropDownLink>
                </SessionRole>
              </div>
            )}
          </div>
        </SessionRole>
        <NavbarLink
          to="/"
          className="ml-2 font-semibold text-4xl sm:ml-3 mr-auto text-white"
        >
          EHC
        </NavbarLink>
        <SessionRole userrole="logged-in">
          <Inserter exclude="/search">
            <NavbarLink to="/search" match="/search">
              <Icon icon="magnifying-glass" className="w-12 text-white" />
            </NavbarLink>
          </Inserter>
          <div className="relative">
            <NavbarLink to="/me" match="/me">
              <NotficationView className="absolute bg-gray-200 rounded-full top-0 right-0 px-1 text-black text-xs mr-2" />
              <img
                width="48"
                height="48"
                src="/user-circle.svg"
                className="w-12 text-white"
                alt="user"
              />
            </NavbarLink>
          </div>
        </SessionRole>
        <SessionRole userrole="logged-out" idd="loginbutton">
          <NavbarLink to="/login" match="/login" className="ml-auto mr-3">
            <img
              width="48"
              height="48"
              src="/user-circle.svg"
              className="w-12 text-white"
              alt="user"
            />
          </NavbarLink>
        </SessionRole>
      </div>
    </header>
  );
};

export default PartMenu;
