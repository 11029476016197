/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef, useState } from "react";
import {
  ContentTypeRender,
  InputWrapper,
  DynamicLineGetSplines,
} from "nystem-components";
import "./input.css";

const Circle = ({ cx, cy, setPoint }) => {
  const [style, setStyles] = useState({ fillOpacity: "0.1" });
  const pointRef = useRef();
  const [moving, setMoving] = useState(false);

  useEffect(() => {
    if (!moving) return;

    const { x, y } = pointRef.current.parentElement.getBoundingClientRect();
    const mouseMove = (event) => {
      const { pageX, pageY } = event;
      setPoint({ cx: parseInt(pageX - x, 10), cy: parseInt(pageY - y, 10) });
    };

    document.addEventListener("mousemove", mouseMove);
    return () => {
      document.removeEventListener("mousemove", mouseMove);
    };
  }, [moving, setPoint]);

  useEffect(() => {
    const mouseUp = () => setMoving(false);

    document.addEventListener("mouseup", mouseUp);
    return () => {
      document.removeEventListener("mouseup", mouseUp);
    };
  }, [setMoving]);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <circle
      className="circle"
      ref={pointRef}
      r="15"
      cx={cx}
      cy={cy}
      fill="grey"
      stroke="black"
      strokeOpacity="1.0"
      strokeWidth="3"
      onMouseOver={() => setStyles({ ...StyleSheet, fillOpacity: "0.5" })}
      onMouseOut={() => setStyles({ ...StyleSheet, fillOpacity: "0.1" })}
      onMouseDown={(e) => {
        if (e.altKey) setPoint(false);
        else setMoving(true);
      }}
      {...style}
    />
  );
};
const Path = ({ onMouseDown, d }) => (
  <path
    className="spline-path"
    fill="none"
    strokeWidth="4"
    onMouseDown={onMouseDown}
    d={d}
  />
);

const defaultVal = [
  { cx: 0, cy: 80 },
  { cx: 151, cy: 182 },
  { cx: 311, cy: 123 },
  { cx: 499, cy: 95 },
];

const DynamicLineInput = ({ model, path, value, setValue }) => {
  const { item } = model;
  value = value || defaultVal;
  if (!value.reduce) value = defaultVal;
  const height = value.reduce((res, { cy }) => (cy > res ? cy : res), 0);
  const paths = DynamicLineGetSplines(value);
  return (
    <InputWrapper model={model}>
      <div className="relative">
        <div className="absolute">
          <ContentTypeRender items={item} />
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 499 ${height + 40}`}
          className="absolute svg max-w-sm"
        >
          {paths.map((path, index) => (
            <Path
              key={index}
              d={path}
              onMouseDown={(e) => {
                const { x, y } = e.target.parentElement.getBoundingClientRect();
                const { pageX, pageY } = e;

                value.splice(index + 1, 0, { cx: pageX - x, cy: pageY - y });

                value[value.length - 1].cx = 499;
                value[0].cx = 0;

                setValue([...value]);
              }}
            />
          ))}
          {value.map((item, index) => (
            <Circle
              key={index}
              {...item}
              setPoint={(pos) => {
                if (!pos) value.splice(index, 1);
                else value[index] = pos;

                value[value.length - 1].cx = 499;
                value[0].cx = 0;

                setValue([...value]);
              }}
            />
          ))}
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 499 ${height + 40}`}
          className="svg z-10 max-w-sm"
        >
          <path
            d={`M 0 0 ${paths.join(" ").replace(/M/g, "L")} L500,0 L0,0 Z`}
            className="fill"
          />
        </svg>
      </div>
      <div>
        Drag circles to change form. Click on line to add points. Alt click to
        remove points.
      </div>
    </InputWrapper>
  );
};

export default DynamicLineInput;
