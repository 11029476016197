import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const LinkView = ({ model, value }) => {
  const className = model.className ? model.className.join(" ") : "";
  return (
    <Wrapper
      className={className}
      renderAs="a"
      target="_blank"
      rel="noopener"
      href={`https://${value.replace(/https?:\/\//, "")}`}
    >
      <ContentTypeRender items={model.item} />
    </Wrapper>
  );
};
export default LinkView;
