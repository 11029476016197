import { useEffect } from "react";
import app from "nystem";

const DatabaseUserSearch = ({ view, children }) => {
  useEffect(() => {
    view.on("search", 0, async (search) => {
      if (!search.filter) return;

      const { data } = await app().database.conference.search({
        filter: { active: true },
        count: 1,
      });
      if (!data) return;

      const { participants = [], _id } = data[0];
      const { data: committees } = await app().database.committee.search({
        filter: { conference: _id },
        count: 2,
      });
      if (!committees) return;

      const people = committees.reduce(
        (res, committee) => [...res, ...committee.members],
        participants
      );

      const $and = search?.filter?.$and || [];
      const searchResult = await app().database[view.contentType].search({
        ...search,
        filter: { $and: $and.filter((item) => !item.conference) },
        data: undefined,
        count: 100,
      });
      console.log(searchResult);
      if (!searchResult.data) return;

      const all = searchResult.data.filter((person) =>
        people.includes(person._id)
      );
      searchResult.data = all.slice(0, search.count);
      searchResult.searchTotal = all.length;

      return searchResult;
    });
  }, [view]);

  return children || null;
};

export default DatabaseUserSearch;
