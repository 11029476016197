import React from "react";
import {
  Wrapper,
  ContentTypeRender,
  DynamicLineGetSplines,
} from "nystem-components";

const defaultVal = [
  { cx: 0, cy: 80 },
  { cx: 151, cy: 182 },
  { cx: 311, cy: 123 },
  { cx: 499, cy: 95 },
];

const getClass = (base, ...rest) => {
  base = base instanceof Array ? base : [base || ""];
  return [...base, ...rest].join(" ");
};

const DynamicLineView = ({ model, path, value }) => {
  const { className, renderAs, item, svgClassName } = model;

  value = value || defaultVal;
  if (typeof value === "string") value = defaultVal;
  const paths = DynamicLineGetSplines(value);
  const height = value.reduce((res, { cy }) => (cy > res ? cy : res), 0);

  return (
    <Wrapper className={getClass(className, "relative")} renderAs={renderAs}>
      <div className="absolute w-full">
        <div className="relative">
          <ContentTypeRender items={item} />
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 499 ${height + 20}`}
        className={getClass(svgClassName, "fill-current")}
      >
        <path
          d={`M 0 0 ${paths.join(" ").replace(/M/g, "L")} L500,0 L0,0 Z`}
          fill="currentColor"
        />
      </svg>
    </Wrapper>
  );
};
export default DynamicLineView;

/*
      <filter id="dropshadow" height="130%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3"></feGaussianBlur>
        <feOffset dx="2" dy="2" result="offsetblur"></feOffset>
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.5"></feFuncA>
        </feComponentTransfer>
        <feMerge>
          <feMergeNode></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
      <circle r="10" style="filter:url(#dropshadow)" />
*/
