import { Wrapper } from "nystem-components";
import React from "react";
import "./view.css";

const mapWith = width => {
  const { clientWidth } = document.documentElement;
  return clientWidth < parseInt(width, 10) ? clientWidth - 60 : width;
};

const MapsView = ({ value, model }) =>
  (value && (
    <Wrapper className="mapouter">
      <Wrapper className={["gmap_canvas", ...(model.className || [])]}>
        <iframe
          title="Hitta hit"
          width={mapWith(model.width)}
          height={model.height}
          id="gmap_canvas"
          src={value}
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
        />
      </Wrapper>
      <style>{`.gmap_canvas { width:${mapWith(model.width)}px }`}</style>
    </Wrapper>
  )) ||
  null;

export default MapsView;
