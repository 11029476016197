import { useEffect } from "react";
import app from "nystem";

const TextSetTextFromFilename = ({ model, setValue, value }) => {
  useEffect(() => {
    const setFromFilename = ({ name }) => {
      if (name && !value) setValue(name.substring(0, name.lastIndexOf(".")));
    };
    app().on("fileUpload", model.prio || 1000, setFromFilename);

    return () => {
      app().off("fileUpload", setFromFilename);
    };
  });

  return null;
};
export default TextSetTextFromFilename;
