import React, { useEffect } from "react";
import app from "nystem";
import { UseSearch, RouterUseQueryStore } from "nystem-components";

const DatabaseBySearchField = ({ model, view, path }) => {
  const { exact, reversesortby: reverse, contentType, filter } = model;
  let { sortby } = model;
  const [value, setValue, ref] = RouterUseQueryStore(model.saveId);

  UseSearch({
    view,
    id: model.field[0].id,
    value,
    exact: model.exact || undefined,
  });

  useEffect(() => {
    const getFilter = () => {
      const insertVal = (val) =>
        val.replace(/\{([a-z_.]+)\}/gim, (str, p1, offset, s) =>
          view.getValue(p1.replace("..", path))
        );

      const $and = [];
      filter.forEach((item) => {
        const oneFilter = {};
        const oneFilterIn = item.and;

        oneFilterIn.forEach((i) => {
          oneFilter[insertVal(i[0])] = insertVal(i[1]);
        });

        $and.push(oneFilter);
      });
      return { $and };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    sortby = sortby instanceof Array ? sortby : sortby && [sortby];

    app()
      .database[contentType].search({
        exact,
        sortby: sortby && sortby.map((item) => item.id),
        reverse,
        contentType,
        count: 1,
        filter: getFilter(),
      })
      .then((result) => {
        setValue(result.data ? result.data[0]._id : undefined);
      });
  }, [setValue]);

  return <div ref={ref} />;
};
export default DatabaseBySearchField;
