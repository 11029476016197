import React, { useEffect, useRef } from "react";
import { ContentTypeRender } from "nystem-components";
import app from "nystem";

const StyleScrollposMemorizer = ({ model, path }) => {
  const { item, direction } = model;
  let { className } = model;
  const element = useRef(null);

  const type = direction === "horizontal" ? "scrollLeft" : "scrollTop";

  useEffect(() => {
    if (!element.current) return;
    let count = 0;

    const setPos = ({ element, type, pos }) => {
      if (element[type] === pos) return;

      count++;
      if (count === 10) {
        count = 0;
        return;
      }

      element[type] = pos;
      setTimeout(() => setPos({ element, type, pos }), 100);
    };

    const pos = app().stateStore.get(element.current);
    if (pos) setPos({ element: element.current, type, pos });

    const storeValue = () => {
      app().stateStore.set(element.current, element.current[type]);
    };
    element.current.addEventListener("scroll", storeValue);
  }, [element, type]);

  className = className instanceof Array ? className : [className];
  return (
    <div ref={element} className={className.join(" ")}>
      <ContentTypeRender path={path} items={item} />
    </div>
  );
};

export default StyleScrollposMemorizer;
