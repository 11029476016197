import React, { useEffect, useState, useContext } from "react";
import { TextInput, DatabaseSearchContext } from "nystem-components";
import app from "nystem";

const LinkExposedField = ({ model }) => {
  const [id] = useState(app().uuid());
  const [className, setClassName] = useState("");
  const [value, setValue] = useState("");
  const [timer, setTimer] = useState(false);
  const { setFilter } = useContext(DatabaseSearchContext);

  useEffect(
    () => () => {
      if (timer) clearTimeout(timer);
    },
    [timer]
  );

  const onChange = value => {
    setClassName("border-orange-300 outline-none");
    console.log({ setFilter: { id, modelId: model.id, value } });
    setFilter({ id, modelId: model.id, value }).then(() => {
      setClassName("border-green-500 outline-none");
      clearTimeout(timer);
      setTimer(setTimeout(() => setClassName(""), 1000));
    });
    setValue(value);
  };

  return (
    <TextInput
      model={{
        ...model,
        clearButton: true,
        inputClassName: `w-full ${className}`,
        selectAllOnFocus: true
      }}
      value={value}
      setValue={onChange}
    />
  );
};
export default LinkExposedField;
