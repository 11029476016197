import React, { useRef, useEffect, useState } from "react";
import { ContentTypeRender, DynamicLineGetSplines } from "nystem-components";

const defaultVal = [
  { cx: 0, cy: 70 },
  { cx: 499, cy: 95 },
];

const getClass = (base, ...rest) => {
  base = base instanceof Array ? base : [base || ""];
  return [...base, ...rest].join(" ");
};

const getPoints = (areas, add) => {
  const count = 6;
  const width = 384;
  const incr = parseInt(width / count, 10);
  let currentHeight = 80;
  const value = [...defaultVal];

  const heights = Array(width).fill(0);

  areas.forEach((area) => {
    for (let pos = parseInt(area.left, 10); pos < area.left + area.width; pos++)
      if (area.top + area.height > heights[pos])
        heights[pos] = area.top + area.height;
  });

  const diff = 2;
  for (let pos = 1; pos < heights.length; pos++) {
    if (heights[pos - 1] - diff > heights[pos])
      heights[pos] = heights[pos - 1] - diff;
  }

  for (let pos = heights.length - 1; pos > 0; pos--) {
    if (heights[pos + 1] - diff > heights[pos])
      heights[pos] = heights[pos + 1] - diff;
  }

  for (let pos = 0; pos < heights.length; pos++) heights[pos] += 20;

  for (let pos = incr; pos < width; pos += incr) {
    const height = heights[pos];

    if (height !== currentHeight) {
      currentHeight = height;
      value.splice(value.length - 1, 0, {
        cx: (pos / 384) * 500,
        cy: height + (add ? parseInt(add, 10) : 100),
      });
    }
  }

  return value;
};

const getBoundingClientRect = (element) => {
  if (element.getBoundingClientRect) return element.getBoundingClientRect();

  return Array(element.length)
    .fill(1)
    .map((no, index) => {
      if (!element.textContent || element.textContent[index] === " ")
        return false;

      const range = document.createRange();
      range.setStart(element, index);
      range.setEnd(element, index + 1);
      return range.getClientRects()[0];
    })
    .filter((test) => test);
};

const getAreas = (element) =>
  element.childNodes.length
    ? [...element.childNodes].map((item) => getAreas(item)).flat()
    : getBoundingClientRect(element);

const AutoDynamicLineView = ({ model }) => {
  const { className, item, svgClassName, add } = model;
  const content = useRef(null);

  const [value, setValue] = useState(defaultVal);

  const paths = DynamicLineGetSplines(value);
  const height = value.reduce((res, { cy }) => (cy > res ? cy : res), 0);

  useEffect(() => {
    if (!content.current) return;
    const { left, top } = content.current.getBoundingClientRect();

    setTimeout(() => {
      setValue(
        getPoints(
          getAreas({
            childNodes: [...content.current.childNodes].slice(1),
          }).map((item) => ({
            left: item.left - left,
            top: item.top - top,
            width: item.width,
            height: item.height,
          })),
          add
        )
      );
    }, 50);
  }, [add]);

  return (
    <div className={getClass(className, "relative")}>
      <div className="absolute w-full">
        <div ref={content} className="relative">
          <ContentTypeRender items={item} />
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 499 ${height + 20}`}
        className={getClass(svgClassName, "fill-current")}
      >
        <path
          d={`M 0 0 ${paths.join(" ").replace(/M/g, "L")} L500,0 L0,0 Z`}
          fill="currentColor"
        />
      </svg>
    </div>
  );
};
export default AutoDynamicLineView;

/*
      <filter id="dropshadow" height="130%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3"></feGaussianBlur>
        <feOffset dx="2" dy="2" result="offsetblur"></feOffset>
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.5"></feFuncA>
        </feComponentTransfer>
        <feMerge>
          <feMergeNode></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
      <circle r="10" style="filter:url(#dropshadow)" />
*/
