import React, { useEffect, useState } from "react";
import app from "nystem";

import {
  Wrapper,
  InputWrapper,
  MediaView,
  TextInput,
  UseValidator,
} from "nystem-components";
import validate from "./validate";

const MediaInput = ({ model, setValue, value, view }) => {
  const [error, setValidated] = UseValidator({ view, validate, value, model });
  const [mediaId] = useState(app().uuid());
  value = value || [];
  if (!(value instanceof Array)) value = [value];

  useEffect(() => {
    const id = mediaId;
    const fileUploadProgress = ({ status, mediaId, ...params }) => {
      if (id !== mediaId) return;
      if (status !== "init") {
        if (params.progress === undefined) return;
        if (params.progress === 100) delete params.progress;
        setValue(
          value.map((item) =>
            item.id === params.id
              ? { ...item, progress: params.progress }
              : item
          )
        );
        return;
      }
      params.progress = 0;
      if (model.limit && value.length + 1 > model.limit) value.shift();
      setTimeout(() => {
        setValue(model.limit === 1 ? params : [...value, params]);
      }, 0);
    };
    app().on("fileUploadProgress", -10, fileUploadProgress);

    return () => {
      app().off("fileUploadProgress", fileUploadProgress);
    };
  }, [mediaId, model.limit, setValue, value]);

  const deleteItem = (pos) => {
    if (value instanceof Array) value.splice(pos, 1);
    else value = [];

    if (model.limit === 1) setValue(value[0]);
    else setValue([...value]);
  };

  const createItem = (item, index) => {
    const path = `${model.id}.${index}.name`;

    return (
      <Wrapper className={["block", model.imageClass]} key={index}>
        <Wrapper className="relative">
          <Wrapper onClick={() => view.event("slideShow", { model, index })}>
            <MediaView
              model={{
                ...model,
                itemClassName: [model.itemClassName, "media-magnify"],
                controls: true,
              }}
              value={item}
              setValue={(val) => {
                if (!item.duration) item.duration = val.duration;
              }}
            />
          </Wrapper>
          <button
            onClick={() => deleteItem(index)}
            type="button"
            className="absolute top-0 right-0 mr-1 mt-1 h-5 w-5 rounded-md bg-red-600 text-2xl leading-4 text-white shadow-xl hover:bg-red-700"
            alt="Remove"
          >
            ×
          </button>
        </Wrapper>
        {model.descripton && (
          <TextInput
            model={{
              text: "Description",
              id: path,
              nolabel: true,
              classNameInput: "w-full mt-1",
            }}
            value={item.name}
            setValue={(value) => view.setValue({ path, value })}
          />
        )}
      </Wrapper>
    );
  };

  // eslint-disable-next-line no-unused-vars
  const createUploadItem = (item, index) => {
    const divStyle = {
      width: `${item.progress}%`,
    };
    return (
      <Wrapper className={["block", model.imageClass]} key={index}>
        <Wrapper className="relative">
          <button
            onClick={deleteItem}
            type="button"
            className="absolute top-0 right-0 mr-1 mt-1 h-5 w-5 rounded-md bg-red-600 text-2xl leading-4 text-white shadow-xl hover:bg-red-700"
          >
            ×
          </button>
          <Wrapper className="w-full bg-gray-200">
            <Wrapper
              className="bg-red-700 h-7 my-1"
              role="progressbar"
              aria-valuenow="45"
              aria-valuemin="0"
              aria-valuemax="100"
              style={divStyle}
            >
              <span>{item.progress}%</span>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Wrapper>
    );
  };

  const accept =
    model.accept ||
    (["image", "audio", "video"].includes(model.filetype)
      ? `${model.filetype}/*`
      : undefined);

  return (
    <InputWrapper model={{ ...model }} error={error}>
      <Wrapper>
        {(!model.limit || value.length < model.limit) && (
          <label className="text-blue border-blue my-1 mb-4 flex w-48 cursor-pointer items-center rounded-lg border bg-white dark:bg-gray-800 dark:hover:bg-gray-700 px-2 py-2 uppercase tracking-wide shadow hover:bg-blue-500 hover:text-white">
            <svg
              className="mr-2 ml-3 h-8 w-8"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
            </svg>
            <span className="ml-1 text-base leading-normal">
              {model.limit !== 1 ? "Select files" : "select file"}
            </span>
            <input
              multiple={model.limit !== 1}
              className="hidden"
              placeholder={app().t(model.text)}
              maxLength={model.length}
              onChange={(e) => {
                for (const pos in e.currentTarget.files)
                  if (!isNaN(parseInt(pos, 10)))
                    app().event("fileUpload", {
                      file: e.currentTarget.files[pos],
                      mediaId,
                      params: [
                        model.secure && "secure",
                        ...(model.addToPath || "").split("/"),
                      ].filter((item) => item),
                      contentType: view.contentType,
                      modelId: model.id,
                      id: app().uuid(),
                    });
              }}
              type="file"
              accept={accept}
            />
          </label>
        )}
        <Wrapper className="flex flex-wrap items-start gap-4">
          {value.map((item, index) =>
            item.progress !== undefined
              ? createUploadItem(item, index)
              : createItem(item, index)
          )}
        </Wrapper>
      </Wrapper>
    </InputWrapper>
  );
};

export default MediaInput;
