import React, { useState } from "react";
import app from "nystem";
import { Button, Wrapper } from "nystem-components";

const getStat = (startTime, downloadSize) => {
  const endTime = new Date().getTime();
  const duration = (endTime - startTime) / 1000;
  const bitsLoaded = downloadSize * 8;
  const speedBps = bitsLoaded / duration;
  const speedKbps = speedBps / 1024;
  const speedMbps = speedKbps / 1024;
  const speedMBps = speedMbps / 8;
  return { speedBps, speedKbps, speedMbps, speedMBps };
};

const ViewButtonDebugDownloadSpeed = ({ model }) => {
  const [value, setValue] = useState();
  const [controller, setController] = useState();

  const measure = async () => {
    const { debugDownloadSpeed } = app().settings;

    try {
      const controller = new AbortController();
      setController(controller);

      let downloadSize = 0;
      const startTime = new Date().getTime();
      const response = await fetch(`/${debugDownloadSpeed}`, {
        signal: controller.signal,
      });

      setTimeout(() => {
        controller.abort();
      }, 10000);

      if (!response.ok) throw new Error(await response.text());

      const reader = response.body.getReader();

      let done = false;
      let value = "";

      while (!done) {
        const startTime2 = new Date().getTime();
        ({ value, done } = await reader.read());

        if (value) {
          downloadSize += value.length;
          console.log(
            getStat(startTime2, value.length).speedMBps,
            value.length
          );
          setValue(getStat(startTime, downloadSize));
        }
      }

      setValue(getStat(startTime, downloadSize));
    } catch (e) {
      console.log(e);
    }
    setController(false);
  };
  const stop = () => {
    controller.abort();
  };

  return (
    <Wrapper className={model.className}>
      <Button
        type={model.btnType}
        onClick={() => (controller ? stop() : measure())}
      >
        {app().t(controller ? "Stop measuring" : "Measure download speed")}
      </Button>
      <Wrapper className="p-3">
        {value &&
          `${value.speedMBps.toFixed(
            3
          )} Megabyte per second (${value.speedMbps.toFixed(1)} Mbps)`}
      </Wrapper>
    </Wrapper>
  );
};

export default ViewButtonDebugDownloadSpeed;
