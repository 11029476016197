import { TextInput } from "nystem-components";
import React from "react";

const MapsInput = ({ setValue, ...props }) => (
  <TextInput
    {...props}
    setValue={(value) => {
      const [, url] = value.match(/<iframe[^>]+src="([^"]+)"/im) || [];
      setValue((url || value).replace(/&#39;/g, "'"));
    }}
  />
);

export default MapsInput;
