import React from "react";
import { Button, InputWrapper, Input, Wrapper } from "nystem-components";
import app from "nystem";

const TokenInput = ({ model, setValue, view, value }) => {
  const content = value ? (
    <Wrapper className="flex">
      <Input
        value={`http${app().settings.secure ? "s" : ""}://${
          app().settings.domain
        }/${model.url}/${value}`}
        selectAllOnFocus="true"
      />
      <Button
        onClick={async () => {
          await setValue(false);
          view.event("submit");
        }}
      >
        {model.cleartext}
      </Button>
    </Wrapper>
  ) : (
    <Button
      onClick={async () => {
        await setValue(true);
        view.event("submit");
      }}
    >
      {model.buttontext}
    </Button>
  );
  return model.wrapper ? (
    <InputWrapper model={model}>{content}</InputWrapper>
  ) : (
    content
  );
};
export default TokenInput;
