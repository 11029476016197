import React, { useState, useEffect } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";

const useUser = () => {
  const [user, setUser] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const { _id, contentType } = app().session.user || {};

      if (!_id) {
        if (user) setUser(false);
        return;
      }

      const { data } = await app().database[contentType].get({ id: _id });

      setUser(data);
    };
    app().connection.on("login", getUser);
    app().connection.on("logout", getUser);

    getUser();
    return () => {
      app().connection.off("login", getUser);
      app().connection.off("logout", getUser);
    };
  }, [user]);

  return user || {};
};

const NotficationRead = ({ model, value, path }) => {
  const { notifications = Date.now() } = useUser();

  const className =
    notifications < value._chdate ? model.className : model.inActiveClassName;

  return (
    <Wrapper className={className} renderAs={model.renderAs}>
      <ContentTypeRender path={path} items={model.item} />
    </Wrapper>
  );
};
export default NotficationRead;
