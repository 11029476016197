import React, { useEffect, useRef } from "react";
import app from "nystem";

const Audio = ({
  src,
  controls,
  preload,
  className,
  playing,
  onChange,
  updateInterval,
  currentTime,
}) => {
  const ref = useRef();
  const source = useRef();

  const first = useRef(true);
  useEffect(() => {
    if (!first.current) return;
    first.current = false;
  }, []);

  useEffect(() => {
    if (playing) {
      const promise = ref.current.play();
      if (promise !== undefined) {
        promise
          .catch(() => {
            onChange({ playing: false });
          })
          .then(() => {
            // Auto-play started
          });
      }
    } else ref.current.pause();
  }, [onChange, playing]);

  useEffect(() => {
    if (
      playing &&
      ref.current &&
      currentTime &&
      Math.abs(ref.current.currentTime - currentTime) > 0.01
    )
      ref.current.currentTime = currentTime;
  }, [currentTime, playing]);

  useEffect(() => {
    if (!onChange) return;

    const ended = () => {
      console.log("audio ended");
      onChange({
        currentTime: 0,
        playing: false,
        isPlaying: false,
      });
    };
    ref.current.addEventListener("ended", ended);

    const paused = () => {
      setTimeout(() => {
        if (updateTick)
          onChange({
            playing: false,
            isPlaying: false,
          });
      }, 100);
    };
    // ref.current.addEventListener("pause", paused);

    const play = () => {
      onChange({ playing: true });
      app().event("media", { audio: ref.current });
    };
    ref.current.addEventListener("play", play);

    const loaded = () => {
      console.log("loaded");
      onChange({ duration: parseInt(ref.current.duration, 10) });
    };
    ref.current.addEventListener("loadeddata", loaded);
    const error = (e) => {
      console.log("error", e.target.src);
      onChange({ error: { src: e.target.src } });
    };
    source.current.addEventListener("error", error);

    let updateTick = setInterval(() => {
      if (playing)
        onChange({
          currentTime: ref.current.currentTime,
          duration: ref.current.duration,
          isPlaying: ref.current.readyState === 4,
        });
    }, updateInterval || 1000);
    app().on("overlay", (data) => {
      if (data.do === "open") onChange({ playing: false });
    });
    // ref.current.currentTime = parseFloat(nextProps.currentTime);

    return () => {
      if (updateTick) clearInterval(updateTick);
      updateTick = false;
    };
  }, [onChange, playing, updateInterval]);

  const ext = src.substring(src.lastIndexOf(".") + 1);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio
      className={className}
      ref={ref}
      controls={controls}
      preload={preload}
    >
      <source ref={source} src={src} type={`audio/${ext}`} />
      Your browser does not support the audio element.
    </audio>
  );
};
export default Audio;
