import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const getClasses = ({ classNameByValue, field, view }) => {
  const testValue = view.getValue(field);
  return classNameByValue.reduce(
    (res, [key, value]) => (key === testValue ? [...res, value] : res),
    []
  );
};

const StyleClassNameByValue = ({ model, path, view }) => {
  const { renderAs, item, mapper } = model;
  let { className } = model;
  className = className instanceof Array ? className : [className];

  className = mapper.reduce(
    (res, value) => [...res, ...getClasses({ ...value, view })],
    className
  );

  return (
    <Wrapper className={className} renderAs={renderAs}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};
export default StyleClassNameByValue;
