import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const ConditionalReference = ({ view, model, value, path }) => {
  const items = value[model.field.id] || [];
  const baseViewId = view.baseView.value._id;

  if (items.some((id) => id === baseViewId))
    return (
      <Wrapper className={model.className}>
        <ContentTypeRender path={path} items={model.item} />
      </Wrapper>
    );

  return null;
};

export default ConditionalReference;
