import React from "react";
import { InputWrapper, ContentTypeRender } from "nystem-components";

const DynamicColorInput = ({ model }) => (
  <InputWrapper model={model}>
    <ContentTypeRender
      path={model.id}
      items={model.colors.map((item) => ({
        ...model,
        ...item,
        format: "picker",
        placeholder: "#fefefe or rgba(255,255,255,10))",
      }))}
    />
  </InputWrapper>
);

export default DynamicColorInput;
