import React, { useContext } from "react";
import { DatabaseSearchContext, ContentTypeView } from "nystem-components";

const ViewListRender = ({ model, view, value }) => {
  const { rowClassName = [] } = model;
  value = model.value || value;

  function createItem(item) {
    const id = ["number", "string"].includes(typeof item) ? item : item._id;

    const settings = {
      view: model,
      contentType: view.contentType,
      id,
      key: id,
      noForm: true,
      baseView: view,
      params: view.params,
      className: rowClassName,
      renderAs: item.renderAs,
      itemRenderAs: item.renderAs === "tr" && "td",
    };

    return <ContentTypeView {...settings} />;
  }

  return value.map(createItem);
};

const attachViewListValue = (items, value) =>
  items.map((item) => {
    if (item.item)
      item = { ...item, item: attachViewListValue(item.item, value) };

    return item.type === "viewList" && item.format === "render"
      ? { ...item, value }
      : item;
  });

const ViewListGroupBySameTime = ({ model, view }) => {
  const { search } = useContext(DatabaseSearchContext);
  const value = (search && search.data) || [];

  const result = value.reduce((res, item) => {
    if (!res.length) return [[item]];
    const last = res.pop();

    if (last[0].to === item.to && last[0].from === item.from)
      return [...res, [...last, item]];

    return [...res, last, [item]];
  }, []);

  return result.map((res, index) => (
    <ViewListRender
      key={index}
      value={[res[0]]}
      model={{
        ...model,
        item: attachViewListValue(model.item, res),
      }}
      view={view}
    />
  ));
};

export default ViewListGroupBySameTime;
