import { useEffect } from "react";
import { UseLocation } from "nystem-components";

const TokenInsertQueryVal = ({ value, model, setValue }) => {
  const { pathname } = UseLocation();

  useEffect(() => {
    if (value) return;

    const token = pathname.split("/")[model.part];
    if (!token) return;

    setValue(token);
  }, [model, setValue, value, pathname]);

  return null;
};
export default TokenInsertQueryVal;
