import {
  Inserter,
  PartMenu,
  SessionRole,
  DragAndDropContext,
  PartAdmin,
  SessionLogout,
  RouterScrollpos,
  ContentTypeView,
  RouterHistory,
  ConnectionView,
  DatabaseAddToCache,
  TinymcePortal,
  RouterPageNotFound,
  RouterRedirect,
} from "nystem-components";
import app from "nystem";
import React from "react";
import "./index.css";

const Index = () => (
  <DragAndDropContext>
    <TinymcePortal />
    <RouterHistory />
    <PartMenu />
    <main className="m-auto relative">
      <PartAdmin />
      <ContentTypeView contentType="conference" format="setcolors" />
      <SessionRole userrole="inactive">
        <h3 className="text-center">
          {app().t("The account is not active, please contact administrator.")}
          <SessionLogout
            type="button"
            className="p-2 text-black rounded cursor-pointer mx-1 hover:underline"
          >
            {app().t("Log out")}
          </SessionLogout>
        </h3>
      </SessionRole>
      <SessionRole userrole="logged-out inactive">
        <Inserter match="/*" source="/page/view/*" />
        <ConnectionView>
          <Inserter
            source="/user/login"
            match="/login*"
            className="m-auto sm:px-20"
          />
        </ConnectionView>
        <Inserter match="/login">
          <ConnectionView offline="true" className="m-auto max-w-sm">
            <h3>Working offline at the moment</h3>
            <p>To be able to log in connect to the internet</p>
          </ConnectionView>
        </Inserter>
        <Inserter source="/page/frontloggedout/loggedoutfirstpage" match="/" />
        <Inserter match="/user/*" />
        <Inserter source="/user/forgotpassword" match="/forgotpassword" />
        <Inserter match="/" source="/conference/sponsorfirstpage" />
        <Inserter match="/" source="/page/viewwithouttitle/footer" />
      </SessionRole>
      <Inserter match="/page/*" />
      <SessionRole userrole="user config super">
        <DatabaseAddToCache contentType="committee" />
        <DatabaseAddToCache contentType="conference" addMedia="true" />
        <DatabaseAddToCache contentType="event" />
        <DatabaseAddToCache contentType="institute" />
        <DatabaseAddToCache contentType="keynote" />
        <DatabaseAddToCache contentType="location" />
        <DatabaseAddToCache contentType="notification" />
        <DatabaseAddToCache contentType="page" addMedia="true" />
        <DatabaseAddToCache contentType="presentation" />
        <DatabaseAddToCache contentType="sponsor" addMedia="true" />
        <DatabaseAddToCache contentType="partner" addMedia="true" />
        <DatabaseAddToCache contentType="user" addMedia="true" />
        <Inserter source="/conference/overview" match="/" />
        <Inserter match="/*" source="/page/view/*" className="" />
        <Inserter match="/user/*" />
        <Inserter match="/settings" source="/user/selectmysettings" />
        <Inserter match="/me" source="/user/me" />
        <Inserter match="/committee/*" />
        <Inserter match="/conference/*" />
        <Inserter match="/institute/*" />
        <Inserter match="/location/*" />
        <Inserter match="/notifications" source="/notification/userlist" />
        <Inserter match="/presentation/*" />
        <Inserter match="/sponsor/*" />
        <Inserter match="/partner/*" />
        <Inserter match="/keynote/*" />
        <Inserter match="/event/*" />
        <Inserter match="/search" source="/event/globalsearch" />
        <Inserter match="/schedule" source="/conference/schedule" />
        <Inserter match="/keynotes" source="/conference/keynotes" />
        <Inserter match="/committee" source="/committee/listall" />
        <Inserter match="/participants" source="/conference/participants" />
        <Inserter match="/sponsors" source="/conference/sponsors" />
        <Inserter match="/partners" source="/conference/partners" />
        <Inserter match="/getaround" source="/location/listall" />
        <Inserter match="/help" source="/user/help" />
        <Inserter match="/login">
          <RouterRedirect to="/" />
        </Inserter>
      </SessionRole>
      <RouterScrollpos />
      <RouterPageNotFound>
        <ContentTypeView contentType="page" format="view" id="404" />
      </RouterPageNotFound>
    </main>
  </DragAndDropContext>
);
export default Index;
