import { useState, useEffect } from "react";
import app from "nystem";

const useUser = () => {
  const [user, setUser] = useState(false);

  useEffect(() => {
    const getUser = () => {
      setUser(app().session.user);
    };
    app().connection.on("login", getUser);
    app().connection.on("logout", getUser);

    getUser();
    return () => {
      app().connection.off("login", getUser);
      app().connection.off("logout", getUser);
    };
  }, []);

  return user || {};
};

const NotficationMarkRead = ({ model }) => {
  const [loadTime] = useState(Date.now());
  const { _id, contentType } = useUser();

  useEffect(() => {
    if (!_id) return;

    let timer = setTimeout(() => {
      timer = false;
      app().database[contentType].save({
        id: _id,
        data: { notifications: loadTime },
        fields: true,
      });
    }, parseInt(model.delay, 10));

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [_id, contentType, loadTime, model]);

  return null;
};

export default NotficationMarkRead;
