import { ContentTypeRender } from "nystem-components";

const MediaBackground = ({ model, value, path = "" }) => {
  const mHeight = model.height ? parseInt(model.height, 10) : 0;
  const mWidth = model.width ? parseInt(model.width, 10) : 0;
  const className = model.className ? model.className.join(" ") : "";
  const density = window.devicePixelRatio;

  const item = value instanceof Array ? value[0] : value;

  let src;
  let style;

  if (item && value) {
    if (mWidth) {
      let height = (mHeight / item.width) * item.height;
      height = height > mHeight ? mHeight : height;
      let width = (mHeight / item.height) * item.width;
      width = width > mWidth ? mWidth : width;
      src =
        item.ext === "svg"
          ? ""
          : `${parseInt(width * density, 10)}x${parseInt(
              height * density,
              10
            )}/`;
      src = `/image/${src}${item.id}.${item.ext}`;
    } else src = `/image/${item.id}.${item.ext}`;

    style = { backgroundImage: `url(${src})` };
  }

  return (
    <div className={` ${className}`} style={style}>
      <ContentTypeRender
        items={model.item}
        path={path.replace(new RegExp(`.${model.id}$`, "i"), "")}
      />
    </div>
  );
};

export default MediaBackground;
