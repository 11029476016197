import React from "react";
import { InputWrapper, Input, Icon, UseValidator } from "nystem-components";
import app from "nystem";
import validate from "./validate";

const ClearButton = ({ value, setValue }) =>
  !value ? null : (
    <Icon
      onClick={() => setValue("")}
      className="absolute w-4 right-0 top-0 m-3"
      icon="close"
      aria-hidden="true"
    />
  );

const LinkInput = ({ model, view, focus, setValue, value }) => {
  const [error, setValidated] = UseValidator({ view, validate, value, model });
  const { disabled, length, text, clearButton, inputClassName = [] } = model;

  return (
    <InputWrapper
      model={{
        ...model,
        classNameLabel: " ",
        classNameInput: "relative flex-grow"
      }}
      error={error}
    >
      <Input
        placeholder={model.placeholder || app().t(text)}
        className={inputClassName}
        value={value || ""}
        maxLength={length}
        onChange={value => setValue(value)}
        disabled={disabled}
        type="text"
        focus={focus}
        onBlur={() => setValidated(true)}
        selectAllOnFocus={model.selectAllOnFocus}
      />
      {clearButton ? <ClearButton value={value} setValue={setValue} /> : null}
    </InputWrapper>
  );
};
export default LinkInput;
