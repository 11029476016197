function evalVal(value, refValue) {
  if (!value) return 0;
  if (typeof value !== "string") return value;
  return parseInt(
    /* eslint-disable no-eval */
    eval(
      value.replace(/([0-9]+)%/g, (str, p1) => {
        return 0.01 * parseInt(p1, 10) * refValue;
      })
    ),
    10
  );
}

const calculateSize = (item, model) => {
  const { width: iWidth, height: iHeight } = item;
  let { width: mWidth, height: mHeight } = model;

  if (!iWidth && !iHeight) return { width: mWidth, height: mHeight };
  if ((!mWidth && !mHeight) || item.ext === "svg")
    return { width: iWidth, height: iHeight };

  mWidth = evalVal(mWidth, window.innerWidth);
  mHeight = evalVal(mHeight, window.innerHeight);

  const iAspect = iWidth / iHeight;
  const mAspect = mWidth / mHeight;

  let height = (mHeight / iWidth) * iHeight;
  height = height > mHeight ? mHeight : height;

  let width = (mHeight / iHeight) * iWidth;
  width = parseInt(width > mWidth ? mWidth : width, 10);

  height = parseInt(iAspect > mAspect ? width / iAspect : height, 10);

  return { width, height, density: window.devicePixelRatio };
};

export default function (app) {
  app.image = { ...(app.image || {}), calculateSize };
};
