import React from "react";
import { ContentTypeView, ContentTypeRender, Wrapper } from "nystem-components";
import ByDayContext from "./byDayContext";

const dayInMs = 1000 * 60 * 60 * 24;

const ViewListByDay = ({ model, view, value }) => {
  const { from, to } = value || {};
  const options = [];

  let acc = parseInt(from, 10);
  while (parseInt(to, 10) >= acc && options.length < 50) {
    options.push(acc);
    acc += dayInMs;
  }

  return options.map((time, index) => {
    const filter = [...((model || {}).filter || [])];
    filter.push({ and: [["from", `>${time}`]] });
    filter.push({ and: [["to", `<${time + dayInMs}`]] });
    const itemModel = { ...model, filter };

    return (
      <Wrapper key={index} className={model.dayClassName}>
        <ByDayContext.Provider key={index} value={{ from: time }}>
          <ContentTypeRender items={model.titleFields} />
          <ContentTypeView
            contentType={view.contentType}
            view={{
              item: [
                { ...itemModel, type: "database", format: "search" },
                { ...itemModel, format: model.viewFormat || "view" },
              ],
            }}
            baseView={view}
          />
        </ByDayContext.Provider>
      </Wrapper>
    );
  });
};
export default ViewListByDay;
