import React, { useContext } from "react";
import { DateView } from "nystem-components";
import ByDayContext from "./byDayContext";

const halfDayInMs = 1000 * 60 * 60 * 12;

const ViewListByDayDate = ({ model, view }) => {
  const { from } = useContext(ByDayContext);

  return <DateView model={model} view={view} value={from + halfDayInMs} />;
};

export default ViewListByDayDate;
