import { useEffect, useState } from "react";
import { UseSearch } from "nystem-components";
import app from "nystem";

const DatabaseSearchAddToFilter = ({ view, model, path, children }) => {
  const { ref } = model;
  const [refVal, setRefVal] = useState();
  UseSearch({ view, id: ref.searchField, value: refVal });

  useEffect(() => {
    app()
      .database[ref.contentType].search({
        filter: { [ref.field]: ref.value },
        exact: true,
      })
      .then(({ data }) => {
        setRefVal(data && data[0][ref.getField]);
      });
  }, [ref, path, view]);

  return children || null;
};

export default DatabaseSearchAddToFilter;
