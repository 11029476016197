import { useEffect } from "react";
import app from "nystem";

const TextTimezone = ({ value }) => {
  useEffect(() => {
    app().settings.timeZone = value;
  }, [value]);

  return null;
};

export default TextTimezone;
