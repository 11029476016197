import React, { useEffect } from "react";
import { InputWrapper, Wrapper, DateInputTime, Icon } from "nystem-components";
import moment from "my-moment";
import app from "nystem";

const DateRelTime = ({ model, view, setValue, value }) => {
  const { relSource } = model;

  const newDate = moment(view.value[relSource]).format("YYYY-MM-DD");
  const time = value && moment(value).format("HH:mm", app().settings.timeZone);
  let current = value && moment(`${newDate} ${time}`).valueOf();
  current =
    value && view.value[relSource] > current ? view.value[relSource] : current;

  useEffect(() => {
    if (value && current !== value) setValue(current);
  }, [value, current, setValue]);

  return (
    <InputWrapper model={model} error={false}>
      <Wrapper className="flex">
        <DateInputTime model={model} value={current} setValue={setValue} />
        {value ? (
          <Icon
            icon="xmark"
            className="mt-1 h-8 w-8 rounded-md bg-red-600 text-white shadow-xl hover:bg-red-700 p-1 mx-1"
            onClick={() => setValue(undefined)}
          />
        ) : (
          <Wrapper className="mx-1 h-8 w-8 " />
        )}
      </Wrapper>
    </InputWrapper>
  );
};
export default DateRelTime;
