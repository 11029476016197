import React from "react";

const getCss = ({ id, value, default: def }) => {
  const val = value || def;
  if (!val) return "";

  return `
    .text-${id} { color: ${val} }
    .text-${id}-20: { color: ${val}20 }
    .bg-${id} { background-color: ${val}; }
    .border-${id} { border-color: ${val}; }
    :root { --color-${id}: ${val}; }
  `;
};

const DynamicColorView = ({ value, model }) =>
  value ? (
    <style
      dangerouslySetInnerHTML={{
        __html: model.colors
          .map((model) => getCss({ ...model, value: value[model.id] }))
          .join(" "),
      }}
    />
  ) : null;

export default DynamicColorView;
