import { Wrapper } from "nystem-components";
import React, { useEffect, useState } from "react";
import app from "nystem";

const UploadProgress = ({ model, ...props }) => {
  const [count, setCount] = useState(0);
  const [failed, setFailed] = useState(0);
  const { className, renderAs } = model || props;

  useEffect(() => {
    let files = [];
    let failed = 0;
    const fileUploadProgress = ({ id, status, progress }) => {
      console.log({ status });
      if (status === "init") {
        files.push({ id, progress });
        setCount(files.length);
      }

      if (status === "done") {
        files = files.filter((item) => item.id !== id);
        setCount(files.length || true);
      }
      if (status === "error") {
        files = files.filter((item) => item.id !== id);
        setCount(files.length || true);
        setFailed(++failed);
      }

      if (progress) setCount(files.length - progress / 100);
    };
    app().on("fileUploadProgress", fileUploadProgress);

    const confirmLeave = (event) => {
      if (files.length)
        event.returnValue = `Upload in progress. Files will be lost if you leave page. Are you sure?`;
      else event.preventDefault();
    };
    // window.addEventListener("beforeunload", confirmLeave);

    return () => {
      // window.removeEventListener("beforeunload", confirmLeave);
      app().off("fileUploadProgress", fileUploadProgress);
    };
  }, []);

  useEffect(() => {
    if (count !== true) return;
    const timer = setTimeout(() => setCount(0), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [count]);

  if (count === 0 && failed === 0) return null;
  return (
    <Wrapper
      className={[
        className,
        count !== true ? "text-yellow-700" : "text-green-800",
      ]}
      renderAs={renderAs}
    >
      <div>
        {count !== true
          ? `Uploading, ${parseFloat(count).toFixed(2)} files to go`
          : "Upload done"}
      </div>
      {failed ? <div>{failed} files failed</div> : null}
    </Wrapper>
  );
};

export default UploadProgress;
