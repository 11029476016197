import React, { useEffect } from "react";
import { Button, Wrapper, RouterUseQueryStore } from "nystem-components";
import app from "nystem";

const MediaPager = ({ view, model, value }) => {
  const [atImage, setAtImage] = RouterUseQueryStore("image");
  value = value || [];

  const at = atImage ? value.findIndex((item) => item.id === atImage) : 0;
  const setAt = (pos) => {
    setAtImage(value[pos].id || 0);
  };

  useEffect(() => {
    setTimeout(() => {
      view.event("atImageEvent", { at });
    }, 0);
  }, [at, view]);

  return (
    <Wrapper className={[model.className, "flex", "items-center", "py-4"]}>
      <Button
        disabled={at === 0 && "disabled"}
        className="mr-2"
        onClick={() => at !== 0 && setAt(at - 1)}
        size={model.size}
      >
        ← {app().t("Previous")}
      </Button>
      {at + 1} of {value.length}
      <Button
        disabled={at === value.length - 1 && "disabled"}
        className="ml-2"
        onClick={() => at !== value.length - 1 && setAt(at + 1)}
        size={model.size}
      >
        {app().t("Next")} →
      </Button>
    </Wrapper>
  );
};

export default MediaPager;
