import { Link, SessionRole, Inserter, Wrapper, Icon } from "nystem-components";
import React from "react";

const NavbarLink = ({ children, type, icon, ...props }) => {
  const classNames =
    "block px-5 py-2 text-white hover:bg-orange-500 hover:shadow-xl flex items-center";

  if (type)
    props = { ...props, to: `/admin/${type}/list`, match: `/admin/${type}/*` };

  return (
    <Link {...props} className={`${classNames} ${props.className || ""}`}>
      <Icon icon={icon} className="w-4 mr-3 h-4" />
      {children}
    </Link>
  );
};

const PartAdmin = () => {
  return (
    <SessionRole userrole="logged-in">
      <Inserter match="/admin*" className="sm:flex admin">
        <Wrapper className="sm:w-40 bg-indigo-500 shadow-xl sm:pb-10 rounded-br-lg sm:mt-0 -mt-6 mb-3">
          <NavbarLink
            icon="gear"
            to="/admin"
            className="font-semibold text-xl px-3 mt-6"
          >
            Admin
          </NavbarLink>
          <NavbarLink icon="calendar" type="event">
            Schedule
          </NavbarLink>
          <NavbarLink icon="user" type="user">
            User
          </NavbarLink>
          <NavbarLink icon="info" type="page">
            Page
          </NavbarLink>
          <NavbarLink icon="key" type="keynote">
            Keynote
          </NavbarLink>
          <NavbarLink icon="user-group" type="committee">
            Committee
          </NavbarLink>
          <NavbarLink icon="house" type="institute">
            Institute
          </NavbarLink>
          <NavbarLink icon="bell" type="notification">
            Notification
          </NavbarLink>
          <NavbarLink icon="photo-film" type="presentation">
            Presentation
          </NavbarLink>
          <NavbarLink icon="industry" type="sponsor">
            Sponsor
          </NavbarLink>
          <NavbarLink icon="industry" type="partner">
            Partner
          </NavbarLink>
          <NavbarLink icon="location-dot" type="location">
            Location
          </NavbarLink>
          <NavbarLink icon="globe" type="conference">
            Conference
          </NavbarLink>
        </Wrapper>
        <Wrapper className="flex-grow px-8 pt-2 pb-8 max-w-4xl">
          <Inserter match="/admin" source="/notification/list" />
          <Inserter match="/admin/page*" source="/page*" />
          <Inserter match="/admin/user*" source="/user*" />
          <Inserter match="/admin/keynote*" source="/keynote*" />
          <Inserter match="/admin/event*" source="/event*" />
          <Inserter match="/admin/presentation*" source="/presentation*" />
          <Inserter match="/admin/conference*" source="/conference*" />
          <Inserter match="/admin/location*" source="/location*" />
          <Inserter match="/admin/sponsor*" source="/sponsor*" />
          <Inserter match="/admin/notification*" source="/notification*" />
          <Inserter match="/admin/institute*" source="/institute*" />
          <Inserter match="/admin/committee*" source="/committee*" />
          <Inserter match="/admin/partner*" source="/partner*" />
        </Wrapper>
      </Inserter>
    </SessionRole>
  );
};

export default PartAdmin;
