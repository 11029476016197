import React, { useState, useEffect } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";

const useSearch = ({ contentType, searchVal, sortby } = {}) => {
  const [result, setResult] = useState([]);

  useEffect(() => {
    if (!contentType) return;

    const db = app().database[contentType];
    const doSearch = () => {
      db.search({
        filter: searchVal || {},
        count: 100,
        data: undefined,
        sortby,
      }).then(({ data }) => setResult(data || []));
    };
    doSearch();
    db.on("update", doSearch);

    return () => {
      db.off("update", doSearch);
    };
  }, [setResult, contentType, searchVal, sortby]);

  return { result };
};

const NotficationItems = ({ model, value, path }) => {
  const { result } = useSearch({
    contentType: "notification",
    searchVal: value && { _chdate: `>${value}` },
  });
  if (!result.length) return null;

  return (
    <>
      <Wrapper className={model.className} renderAs={model.renderAs}>
        {result.length}
      </Wrapper>
      <ContentTypeRender path={path} items={model.item} />
    </>
  );
};
export default NotficationItems;
