import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const defaultVal = { x: 0, y: 0, zoom: 1 };

const CssmaskView = ({ model, value }) => {
  const { item } = model;
  const size = parseInt(model.size, 10);
  const { x, y, zoom } = value || defaultVal;
  const className = model.className || [];

  return (
    <Wrapper
      className={`relative overflow-hidden ${className.join(" ")}`}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <Wrapper
        className="absolute"
        style={{
          left: `${parseInt((x * size) / 255, 10)}px`,
          top: `${parseInt((y * size) / 255, 10)}px`,
          width: `${parseInt(zoom * size, 10)}px`,
          height: `${parseInt(zoom * size, 10)}px`,
        }}
      >
        <ContentTypeRender items={item} />
      </Wrapper>
    </Wrapper>
  );
};
export default CssmaskView;
