import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";

const ConditionalSettings = ({ model, path }) => {
  const { className, item, field, invert } = model;

  const val = app().settings[field];
  if ((val && invert) || (!invert && !val)) return null;

  return (
    <Wrapper className={className}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};
export default ConditionalSettings;
