import React, { useState, useEffect } from "react";
import { Wrapper, DatabaseGet } from "nystem-components";
import app from "nystem";

const useSearch = ({ contentType, chdate, sortby } = {}) => {
  const [result, setResult] = useState([]);

  useEffect(() => {
    if (!contentType) return;

    const db = app().database[contentType];

    const doSearch = () => {
      db.search({
        filter: { _chdate: `>${chdate || 0}`, $and: [{ published: true }] },
        count: 100,
        data: undefined,
        sortby,
      }).then(({ data }) => setResult(data || []));
    };
    doSearch();
    db.on("update", doSearch);

    return () => {
      db.off("update", doSearch);
    };
  }, [contentType, chdate, sortby]);

  return { result };
};

const useUser = () => {
  const [user, setUser] = useState(false);

  useEffect(() => {
    const getUser = () => {
      setUser(app().session.user);
    };
    app().connection.on("login", getUser);
    app().connection.on("logout", getUser);

    getUser();
    return () => {
      app().connection.off("login", getUser);
      app().connection.off("logout", getUser);
    };
  }, []);

  return user || {};
};

const NotficationView = ({ model = {}, className, renderAs }) => {
  const { _id, contentType } = useUser();
  const { data = {} } = DatabaseGet({ id: _id, contentType, autoUpdate: true });

  const { result } = useSearch({
    contentType: _id ? "notification" : false,
    chdate: data.notifications,
  });

  if (!result.length) return null;

  return (
    <Wrapper
      className={className || model.className}
      renderAs={renderAs || model.renderAs}
    >
      {result.length}
    </Wrapper>
  );
};
export default NotficationView;
