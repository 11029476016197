import React from "react";
import moment from "my-moment";
import app from "nystem";
import { Wrapper } from "nystem-components";

const dateTimeFormats = {
  dateLong: "YYYY-MM-DD",
  timeLong: "H:mm",
  dateTimeLong: "YYYY-MM-DD HH:mm",
  dateTimeNoYear: "D/M H:mm",
  dateTimeNoYearDay: "ddd D/M H:mm",
  dateNoYearDay: "ddd D/M",
  timeDay: "ddd D/M H:mm",
  day: "dddd",
};
const DateSuperscript = ({ model, value }) => {
  const { className, renderAs, dateFormat, supClassName } = model;
  const format = dateTimeFormats[dateFormat || "dateTimeLong"];
  const [hour = "", min = ""] = value
    ? moment(value).format(format, app().settings.timeZone).split(":")
    : "";

  return (
    <Wrapper renderAs={renderAs} className={className}>
      {hour}
      <Wrapper renderAs={"sup"} className={supClassName}>
        {min}
      </Wrapper>
    </Wrapper>
  );
};

export default DateSuperscript;
