/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { ContentTypeView, Wrapper } from "nystem-components";
import app from "nystem";

const findCss = () => {
  const csss = [...document.head.children].find(
    (child) =>
      child.getAttribute("rel") === "preload" &&
      child.getAttribute("href").endsWith(".css")
  );
  return csss ? csss.getAttribute("href") : "/index.css";
};

const TinymcePlugin = ({ path, view, model }) => {
  const [state, setState] = useState(false);
  const contentType = model.contentType || view.contentType;

  useEffect(() => {
    const id = path + model.id;

    const tinymceSetup = ({ editor }) => {
      editor.ui.registry.addButton(`nystem${model.name}`, {
        tooltip: model.tooltip,
        icon: model.icon,
        onAction: async () => {
          setState({
            setValue: (value) => {
              editor.insertContent(
                `<nystem-component contenttype="${contentType}" format="${
                  model.view
                }" value="${JSON.stringify(value).replace(
                  /"/g,
                  "'"
                )}"></nystem-component>&nbsp;`
              );
            },
            value: {},
          });

          return false;
        },
      });
    };

    const tinymceInit = (query) => ({
      ...query,
      settings: [...query.settings, { buttons: [`nystem${model.name}`] }],
    });

    const tinymceComponent = (initial) =>
      initial.Component ||
      initial.contentType !== contentType ||
      initial.format !== model.view
        ? undefined
        : {
            ...initial,
            Component: () => {
              const [current, setCurrent] = useState(initial.value);

              return (
                <Wrapper
                  className={model.className}
                  style={{ display: "contents" }}
                  onClick={(e) => {
                    e.preventDefault();

                    setState({
                      value: current,
                      setValue: (value) => {
                        initial.setValue(value);
                        setCurrent({});
                        setTimeout(() => setCurrent(value), 0);

                        view.event(`tinyMceEditorSave${id}`);
                      },
                    });
                  }}
                >
                  <link href={findCss()} rel="stylesheet" />
                  <ContentTypeView
                    contentType={contentType}
                    format={model.viewInInput}
                    value={current}
                    baseView={initial.baseView}
                  />
                </Wrapper>
              );
            },
          };

    app().on(`tinymceComponent`, tinymceComponent);
    view.on(`tinymceInit${id}`, tinymceInit);
    view.on(`tinymceSetup${id}`, tinymceSetup);

    return () => {
      app().off(`tinymceComponent`, tinymceComponent);
      view.off(`tinymceInit${id}`, tinymceInit);
      view.off(`tinymceSetup${id}`, tinymceSetup);
    };
  }, [contentType, model, path, view]);

  if (!state) return null;

  return (
    <ContentTypeView
      contentType={contentType}
      format={model.inputView}
      value={state.value}
      baseView={view}
      onSave={(value) => {
        state.setValue(value);
        setState(false);
      }}
      onCancel={() => setState(false)}
      onDelete={() => {
        state.setValue(false);
        setState(false);
      }}
    />
  );
};
export default TinymcePlugin;

customElements.define(
  "nystem-component",
  class extends HTMLElement {
    constructor() {
      super();
      this.portalId = app().uuid();
    }

    async connectedCallback() {
      const value = this.getAttribute("value");
      const settings = {
        contentType: this.getAttribute("contenttype"),
        format: this.getAttribute("format"),
        value: value && JSON.parse(value.replace(/'/g, '"')),
      };

      let element = this.attachShadow({ mode: "open" });

      this.timer = setTimeout(async () => {
        // eslint-disable-next-line prefer-const
        let { Component, baseView } = await app().event(`tinymceComponent`, {
          ...settings,
          viewId: this.closest(".tinymceview").id,
          element,
          setValue: (value) =>
            value
              ? this.setAttribute(
                  "value",
                  JSON.stringify(value).replace(/"/g, "'")
                )
              : this.parentElement.removeChild(this),
        });

        if (!Component) {
          const reactRoot = document.createElement("div");
          reactRoot.style.display = "contents";
          element.appendChild(reactRoot);
          element = reactRoot;

          Component = () => {
            useEffect(() => {
              this.viewUnmount = true;
              if (this.parentElement && !this.closest(".tinymceinput"))
                this.parentElement.replaceChild(element, this);
            }, []);

            return <ContentTypeView {...settings} baseView={baseView} />;
          };
        }

        app().event(`reactPortal`, {
          id: this.portalId,
          Component,
          element,
          viewId: this.closest(".tinymceview").id,
        });
      }, 20);
    }

    disconnectedCallback() {
      clearTimeout(this.timer);
      if (!this.viewUnmount) app().event(`reactPortal`, { id: this.portalId });
    }
  }
);
