import React, { useState, useEffect } from "react";
import { Wrapper } from "nystem-components";
import app from "nystem";

const fetchCache = {};

const ViewOneStat = ({ items, name }) =>
  items.length ? (
    <Wrapper renderAs="div">
      <strong>
        {name} {items.length}
      </strong>{" "}
      {items
        .map((item) => item._id)
        .map((id, index) => (
          <div key={index}>{id}</div>
        ))}
    </Wrapper>
  ) : null;

const TestOneContentType = ({ contentType }) => {
  const [stat, setStat] = useState(false);

  useEffect(() => {
    const getStat = async () => {
      const { tokenId } = await app().connection.emit({
        type: "dbTestToken",
        contentType,
      });

      let { data: serverData, total } = tokenId
        ? await window
            .fetch(`/dbtest/${tokenId}.json`)
            .then((res) => res.json())
        : {};

      if (!serverData)
        ({ serverData = [], total } = fetchCache[contentType] || {});
      else fetchCache[contentType] = { serverData, total };

      const { role } = app().session.user;
      const { search = [] } = app().contentType[contentType]._roles;

      const noSearch = role !== "super" && !search.includes(role);
      let { data } = await app().database[contentType].search({
        count: 1000,
      });
      data = data || [];

      if (noSearch && serverData.length)
        data = await Promise.all(
          serverData.map(async (item) => {
            const { data } = await app().database[contentType].get({
              id: item._id,
            });
            return data;
          })
        );

      setStat({
        total,
        totalAccessable: serverData.length,
        onlyServer: serverData.filter(
          (item) => !data.find((item2) => item._id === item2._id)
        ),
        onlyClient: data.filter(
          (item) => !serverData.find((item2) => item._id === item2._id)
        ),
        changed: data.filter((item) => {
          const serverItem = serverData.find((item2) => item._id === item2._id);

          return (
            serverItem && JSON.stringify(serverItem) !== JSON.stringify(item)
          );
        }),
      });

      console.log(tokenId, serverData);
    };
    getStat();
  }, [contentType]);

  if (!stat)
    return (
      <>
        <Wrapper renderAs="h3">{contentType}</Wrapper>
        <Wrapper renderAs="div">Loading...</Wrapper>
      </>
    );

  return (
    <>
      <Wrapper renderAs="h3">{contentType}</Wrapper>
      <Wrapper renderAs="div">
        Count {stat.totalAccessable} ({stat.total})
      </Wrapper>
      <ViewOneStat name="Server" items={stat.onlyServer} />
      <ViewOneStat name="Client" items={stat.onlyClient} />
      <ViewOneStat name="Different" items={stat.changed} />
    </>
  );
};

const DbtestView = ({ view = {}, model = {} }) => {
  return (
    <Wrapper className={model.className}>
      {Object.keys(app().contentType).map((name, index) => (
        <TestOneContentType
          key={index}
          view={view}
          model={model}
          contentType={name}
        />
      ))}
    </Wrapper>
  );
};

export default DbtestView;
