import React, { useEffect, useRef, useState } from "react";

import { Wrapper, ContentTypeRender } from "nystem-components";

const StyleSurroundSpace = ({ model, path }) => {
  const { className, item } = model;
  const wrap = useRef();
  const [after, setAfter] = useState(" ");

  useEffect(() => {
    if (!wrap.current) return;

    const element = wrap.current;
    const getParent = () =>
      new Promise((resolve) => {
        const check = () => {
          if (!element.parentElement) {
            resolve();
            return;
          }
          if (
            element.parentElement.parentNode.nodeName === "#document-fragment"
          )
            setTimeout(check, 100);
          else resolve(element.parentElement.parentNode);
        };
        check();
      });

    const check = async () => {
      const parent = await getParent();
      if (!parent) return;

      const parents = [...parent.childNodes];
      const pos = parents.indexOf(element.parentElement);

      if (
        parents[pos + 1] &&
        [",", "."].includes(parents[pos + 1].textContent[0])
      )
        setAfter("");
    };

    check();
  }, []);

  return (
    <Wrapper ref={wrap} className={[className, "inline"]}>
      {" "}
      <ContentTypeRender path={path} items={item} />
      {after}
    </Wrapper>
  );
};
export default StyleSurroundSpace;
